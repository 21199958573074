import React, { useEffect, useState } from 'react'
import './corporateEventMainContent.css'
import { FiArrowLeft } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { useRef } from 'react';
import corporateNews from './corporateEventNews';

function CorporateEventMainContent({searchText,sortText}) {
  const [data, setData] = useState([]);
    const [currentLatestPosts, setCurrentLatestPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(4);
    const [currentPosts, setCurrentPosts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [postAvailable, setPostAvailable] = useState(true);
    const hasMounted = useRef(false);

    //Get Data in reverce from PressReleaseNews.js file. So we can get latest data 1st
    useEffect(()=>{
        if(sortText == 1){
            setData(corporateNews.slice().reverse());  
        }else{
            setData(corporateNews.slice());
            
        }
    },[sortText])

    useEffect(()=>{
        if(sortText == 1){
            setCurrentLatestPosts(data.slice(0, 3));  
        }
    },[data])

    //When Click next page, Need to go to the top of the webpage.
    useEffect(()=>{
        if (hasMounted.current) {
            if (typeof window !== 'undefined') {
                if(window.innerWidth>1000){
                    window.scrollTo({
                        top: (90 / 100) * window.innerHeight,
                        behavior: 'smooth'
                    });
                }else if(window.innerWidth>750 && window.innerWidth<1000){
                    window.scrollTo({
                        top: (50 / 100) * window.innerHeight,
                        behavior: 'smooth'
                    });
                }else if(window.innerWidth>480 && window.innerWidth<750){
                    window.scrollTo({
                        top: (37 / 100) * window.innerHeight,
                        behavior: 'smooth'
                    });
                }else if(window.innerWidth<480){
                    window.scrollTo({
                        top: (30 / 100) * window.innerHeight,
                        behavior: 'smooth'
                    });
                }
            }
        } else {
            hasMounted.current = true;
        }
    },[currentPage])
    
    //Left Side Latest Posts

    //Get last and first posts index to seperate the some posts from all posts
    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    
    //Search Function
    useEffect(() => {
        if (data.length > 0) {
            setPostAvailable(true);
            if(searchText !== null && searchText !== ''){
                const filteredPosts = data.filter((item)=>item.title.toLowerCase().includes(searchText.toLowerCase()));
                setCurrentPage(1);
                if(filteredPosts.length > 0){
                    setCurrentPosts(filteredPosts.slice(indexOfFirstPost, indexOfLastPost));

                    //Find total pages count to add pagination number
                    setTotalPages(Math.ceil(filteredPosts.length/postPerPage));
                }else{
                    setPostAvailable(false);
                }
                
            }else{
                setCurrentPosts(data.slice(indexOfFirstPost, indexOfLastPost));
                //Find total pages count to add pagination number
                setTotalPages(Math.ceil(data.length/postPerPage));
            }
        }
    }, [data, currentPage, searchText]);

    

    //Chnage page using forward and backward btns
    const changeCurrentPage = (type)=>{
        if(type == 'forward'){
            if(currentPage < totalPages){
                setCurrentPage(currentPage + 1);
            }
        }else if(type == 'backward'){
            if(currentPage > 1){
                setCurrentPage(currentPage - 1);
            }
        }
    }


  return (
    <div className='corporate-main-content-container'>
        <div className='corporate-main-content-container-left'>
            <div className="corporate-main-left-top">
                <h3>Latest News</h3>
                <div className="corporate-main-left-news-container">
                    {currentLatestPosts.map((news)=>(
                        <div className="corporate-main-left-news" >
                            <div className='corporate-main-left-news-image'>
                                <img src={news.imageURL} alt="" />
                            </div>
                            <div className='corporate-main-left-news-content'>
                                <small>{news.date +' '+ news.month}</small>
                                <p>{news.title.length > 34 ? news.title.charAt(34) == ' ' ? news.title.substring(0, 34) + '...': news.title.substring(0, 35) + '...': news.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>


        {/* Right Section */}
        <div className='corporate-main-content-container-right'>
            {postAvailable ?
                currentPosts.map((item)=>(
                    <div className="corporate-main-container-right-news">
                        <div className="corporate-main-container-right-news-image">
                            <img src={item.imageURL} alt="" />
                            <div className='corporate-main-container-right-news-image-date'>
                                <p>{item.date}<br />{item.month}</p>
                            </div>
                        </div>
                        <div className="corporate-main-container-right-news-content">
                            <small className='corporate-main-container-right-news-content-small'>Press Release</small>
                            <h2>{item.title}</h2>
                            <p>{item.body}</p>
                            <button className='corporate-news-target_button'>
                                Read more
                                <FaArrowRight className='corporate-news-target_button-icon' />
                            </button>
                        </div>
                    </div>
                ))
                

            :
                <h3 style={{textAlign:'center', paddingBottom:'100px'}}>No news available</h3>
            }
            {
                postAvailable && 
                    <div className='pagination'>
                        <div onClick={()=>changeCurrentPage('backward')} className='pagination-btn'><FiArrowLeft size={24} color={` ${currentPage > 1 ? 'rgba(0, 25, 76, 1)' : 'rgba(0, 25, 76, 0.5)'} `}/></div>
                        {new Array(totalPages).fill(0).map((_,index)=>{
                            return <div className={`pagination-btn ${currentPage == (index+1) ? 'active': ''}`} key={index+2} onClick={()=>setCurrentPage(index+1)}>{(index+1) < 10 ? '0'+(index+1): (index+1)}</div>
                        })}
                        <div onClick={()=>changeCurrentPage('forward')} className='pagination-btn'><FiArrowRight size={24} color={` ${currentPage < totalPages ? 'rgba(0, 25, 76, 1)' : 'rgba(0, 25, 76, 0.5)'} `}/></div>
                    </div> 
            }
            
        </div>
    </div>
  )
}

export default CorporateEventMainContent
