import React from 'react'
import CorporateEventBG from '../../images/corporate-event-bg.webp'
import './corporateEventMainBanner.css'


function CorporateEventMainBanner() {
  return (
    <div>
        <div>
            <div style={{ position: "relative" }}>
                <img className="corporate-event-home-image" alt="" src={CorporateEventBG} />
                <div className="corporate-event-image-text-box">
                <div className="corporate-event-image-background-text">
                    <h1 className="corporate-event-image-topic">
                        Corporate Events
                    </h1>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CorporateEventMainBanner
