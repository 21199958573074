import React from 'react'
import CorporateEventHome from '../templates/CorporateEvent/corporateEventHome'

function CorporateEvent() {
  return (
    <div>
      <CorporateEventHome />
    </div>
  )
}

export default CorporateEvent
