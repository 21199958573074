const corporateNews = [
    {
        id:1,
        title:'CIC Manussakama was relaunched with the 1st Danuma Daanaya community program',
        body:'CIC Manussakama was relaunched with the 1st Danuma Daanaya community program for school children taking place at Thambuththegama Central College, on 30th November 2016. This seminar was conducted by Mr.Attanayaka M.Herath, Mr.Rohana Baddage and Mr.Wassantha Duggannarala to enlighten over 650 students. These seminars will help...',
        date:'16',
        month:'feb',
        imageURL:require('../../images/corporate_news_image_01.jpg').default,
    },
    {
        id:2,
        title:'Employees of CIC visited ‘Sucharithodaya’',
        body:'Employees of CIC visited ‘Sucharithodaya’, Pamunuwa,Maharagama on the 29th of November 2016 as a part of the company’s CSR Initiative. The employees spent time with the residents there and did their part to make life better for them.',
        date:'16',
        month:'feb',
        imageURL:require('../../images/corporate_news_image_02.png').default,
    },
    {
        id:3,
        title:'CIC staff members came into work wearing their new T-shirts, branded with the new corporate logo and colours',
        body:'CIC staff members came into work wearing their new T-shirts, branded with the new corporate logo and colours on the 9th of September 2016. ‘T-shirt Day’ fostered a spirit of comradery and elevated levels of fellowship and understanding among staff members while embracing the new Corporate branding at CIC Holdings.',
        date:'16',
        month:'feb',
        imageURL:require('../../images/corporate_news_image_03.jpg').default,
    },
    {
        id:4,
        title:'CIC celebrated Children’s Day',
        body:'CIC House was filled with smiles and laughter when CIC celebrated Children’s Day on the 14th of October 2016. An art competition was held engaging employees’ families as a part of the event. A total number of 30 children took part in this art competition within the age group 3 to 16 years.',
        date:'16',
        month:'feb',
        imageURL:require('../../images/corporate_news_image_04.jpg').default,
    },
    {
        id:5,
        title:'CIC Annual Christmas Get–together',
        body:'CIC Annual Christmas Get–together was held at Hilton Colombo Residences on the 18th of December 2016. This annual event brought together approximately 1000 participants including employee’s spouses and children to enjoy the spirit of Christmas.',
        date:'16',
        month:'feb',
        imageURL:require('../../images/corporate_news_image_05.jpg').default,
    }
]

export default corporateNews;