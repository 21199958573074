import React,{ useState} from 'react'
import './corporateEventHome.css'
import CorporateEventMainBanner from './corporateEventMainBanner'
import SearchBox from './searchBox'
import CorporateEventMainContent from './corporateEventMainContent';


function CorporateEventHome() {

  const [searchText, setSearchText] = useState('');
  const [sortText, setSortText] = useState(1);


  return (
    <div>
        <CorporateEventMainBanner />
        <SearchBox
          setSearchText={setSearchText}
          setSortText={setSortText}
        />
        <CorporateEventMainContent 
          searchText={searchText}
          sortText={sortText}
        />
    </div>
  )
}

export default CorporateEventHome
